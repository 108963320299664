<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="标题">
              <a-input
                v-decorator="[
                  'title',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="有效时间范围">
              <a-range-picker
                :showTime="true"
                v-decorator="[
                  'date',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="评分指标"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
            >
              <Subject :list="list" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="被评分人"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
            >
              <User :list="users" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="评委"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
            >
              <Judges :list="judges" />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="loading"
              >保存</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import { add } from "./api";
import Subject from "./components/subject.vue";
import User from "./components/user.vue";
import Judges from "./components/judges.vue";
export default {
  name: "technicalQualityScoreAdd",

  components: {
    Subject,
    User,
    Judges,
  },

  data() {
    return {
      form: this.$form.createForm(this),

      list: [],
      users: [],
      judges: [],

      loading: false,
    };
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.list.length) {
            this.$message.error("请填写评分指标");
            return;
          }
          if (!this.users.length) {
            this.$message.error("请选择被打分人");
            return;
          }
          if (!this.judges.length) {
            this.$message.error("请选择评委");
            return;
          }

          this.loading = true;

          let startTime = values.date[0].format("YYYY-MM-DD HH:mm:ss");
          let endTime = values.date[1].format("YYYY-MM-DD HH:mm:ss");

          const subjectList = this.list.map((item) => {
            const optionList = [];
            for (let i = 1; i <= item.value; i++) {
              optionList.push({
                optionName: String(i),
                optionValue: String(i),
              });
            }
            return {
              ...item,
              type: "radio",
              value: undefined,
              optionList,
            };
          });

          add({
            title: values.title,
            isAnonymous: 0,
            startTime,
            endTime,

            subjectList,
            scoredPersonalList: this.users,
            judgesGroupList: [
              {
                groupName: "默认组",
                weight: 1,
              },
            ],
            judgesList: this.judges,
          })
            .then(() => {
              this.$close(this.$route.path);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
.container {
  padding: 12px;
}

.center {
  margin-top: 80px;
  margin-bottom: 80px;
}
</style>